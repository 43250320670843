import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.2_@babel+core@7.26.10_@opentelemetry+api@1.9.0_@playwright+test@1.51.1_react-dom@19_lvb66i5oqinun7jjww3aaparbe/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.2_@babel+core@7.26.10_@opentelemetry+api@1.9.0_@playwright+test@1.51.1_react-dom@19_lvb66i5oqinun7jjww3aaparbe/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"500\",\"600\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/lib/analytics/AnalyticsLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/lib/general/PathHistory.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/src/lib/providers/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/lib/styles/builder.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/lib/styles/globals.css");
